<template>
    <v-card rounded="lg">
        <v-toolbar tile dark class="bg-gradient">
            <v-btn
                depressed
                color="white"
                class="square mr-2"
                text
                @click.native="$emit('closeModal')"
            >
                <v-icon dark small>$prev</v-icon>
            </v-btn>
            <v-toolbar-title>{{$t('reaffectVendorsManagement')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn depressed outlined color="white" class="mr-3" @click="resetAffectations()"> 
                {{$t('resetLabel')}}
            </v-btn>
            <v-btn depressed outlined color="white" class="mr-3" @click="$emit('closeModal')">
                {{$t('closeModalLabel')}}
            </v-btn>
            <v-btn depressed outlined color="white" :loading="loading" @click="saveAffectation()" v-if="retro.status != 'DRAFT'">
                {{$t('regenerateCall')}}
            </v-btn>
        </v-toolbar>
        <v-card-text class="pa-4">
            <v-card>
            <v-data-table :items="campAffectation.data" :headers="campAffectation.headers" item-key="user_id" disable-sort hide-default-footer :single-expand="true" :expanded.sync="expanded" :item-class="itemRowBackground" disable-pagination>
                <template v-for="header in campAffectation.headers"  v-slot:[`header.${header.value}`]="{  }">
                    <!-- Surcharge des actions en fin de ligne -->
                    <div v-if="header.value === 'actions'"  class="" :key="header.value">
                    </div>
                    <!-- Pour le reste des colonnes -->
                    <div v-else class="standardColHeader" :key="'stand_'+header.value">{{$t(header.text)}}</div>
                </template>
                <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
                    <v-btn class="square" v-if="item.items && item.items.length" text @click.stop="expand(!isExpanded)">
                        <v-icon color="primary" :class="isExpanded ? 'iconExpand rotate' : 'iconExpand'">$dropdown</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.assignment_original="data">
                    <div @click="clickColumn(data)" class="d-flex align-center" style="cursor:pointer;">
                        <span class="d-flex align-center" style="min-width:25%">
                            {{ data.item.assignment_original }}
                            <v-icon v-if="data.item.items && data.item.items.length>0" class="ml-4">$dropdown</v-icon>
                        </span>
                        <v-btn small depressed v-if="data.item.user != 'Total' && data.item.toReaffect > 0" @click.stop="reaffectFor(data.item)" color="primary">Réattribuer</v-btn>
                    </div>
                </template>
                <template v-slot:item.n="data">
                    <div class="d-flex align-center justify-center" >
                        <span class="d-flex align-center justify-center" style="min-width:25%">
                            {{ data.item.n }}
                            <!-- <div :class="'ml-3 ' + (getMovementOnItem(data.item) > 0 ? 'success--text' : 'error--text')" v-if="newAffectation.length > 0">
                               (<span v-if="getMovementOnItem(data.item) > 0">+</span>{{getMovementOnItem(data.item)}})
                            </div> -->
                        </span>
                    </div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="campAffectation.headers.length + 1" class="px-0">
                        <v-simple-table style="border-radius:0px;">
                            <template v-slot:default>
                                <tbody>
                                    <tr
                                    v-for="item in item.items"
                                    :key="'subtable_'+item.assignment_original+'_'+item.user_id"
                                    style="background-color:#f9f9f9"
                                    >
                                    <td style="width:30%">{{ item.user }}</td>
                                    <td style="width:30%">
                                        <span class="d-flex align-center">
                                            <span class="d-block" style="min-width:25%">
                                                {{ item.assignment_original }}
                                            </span>
                                            <v-btn small depressed @click="reaffectFor(item)" v-if="item.toReaffect > 0" color="primary">Réattribuer</v-btn>
                                        </span>
                                    </td>
                                    <td style="width:10%" class="text-center">{{ item.closed }}</td>
                                    <td style="width:10%" class="text-center">{{ item.new }}</td>
                                    <td style="width:10%" class="text-center">{{ item.callToCreate }}</td>
                                    <td style="width:10%" class="text-center">
                                        {{ item.n }}
                                        <!-- <div class="ml-3" v-if="newAffectation.length > 0">
                                            ({{getMovementOnItem(item)}})
                                        </div> -->
                                    </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </td>
                </template>
            </v-data-table>
            </v-card>
        </v-card-text>
        <v-dialog width="900" v-model="reaffectDialog" scrollable>
            <v-card v-if="toReaffect && toReaffect.user_id">
                <v-toolbar tile dark class="bg-gradient">
                    <v-toolbar-title>{{$t('reaffectContacts')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                        <v-btn depressed outlined color="white" class="mr-3" @click="reaffectDialog = false">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" :loading="false" @click="addNewAffectation">
                        {{$t('recordAffectationLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-4">
                 
                    <v-simple-table style="border-radius:0px;">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th colspan="2">
                                        {{ $t('contactSource') + " : "+ toReaffect.assignment_original }}
                                    </th>
                                    <th>
                                        Nouveau Solde
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                v-for="item in affectationAvailable"
                                :key="'affectationtable_'+item.assignment_original+'_'+item.user_id+affectationKey"
                                >
                                    <td style="width:30%">{{ item.user }}</td>
                                    
                                    <td>
                                        <div class="d-flex align-center">
                                            <div style="width:80px;" class="input-form mr-2">
                                                <v-text-field type="number" width="80" min="0" :max="toReaffect.toReaffect" @blur="getCountElement(item)" solo flat dense hide-details v-model="item.toAdd"></v-text-field>
                                            </div>
                                            <b>/ {{ toReaffect.toReaffect }}</b>
                                        </div>
                                    </td>
                                    <td class="newSolde text-center" style="width:15%;">
                                       <b>{{ item.newTotal  }}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <div class="black--text mt-4" :key="'newSolde_'+affectationKey">
                        {{ $t('newSoldeFor') + " "+ toReaffect.user + " "+ $t('forSource') + " " + toReaffect.assignment_original + " : "}} <b>{{ newSolde }}</b>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
export default {
    name:"reaffectVendorsComponent",
    props: ['rpId'],
    data() {
        return {
            retro: {},
            campAffectation:[],
            expanded:[],
            newAffectation:[],
            reaffectDialog: false,
            affectationAvailable: [],
            toReaffect:{},
            affectationKey:0,
            newSolde: 0,
            loading: false
        }
    },
    watch: {
        rpId(val) {
            if (val) {
                GenericDataService.getData('/retroplanning/' + this.rpId + '/get').then((response) => {
                    this.retro = response.data.data;
                });
                GenericDataService.getData('/retroplanning/' + val + '/getCampAffectation').then((response) => {
                    this.campAffectation = response.data.data;
                });
            }
        },

    },
    created() {
        if (this.rpId) {
            GenericDataService.getData('/retroplanning/' + this.rpId + '/get').then((response) => {
                this.retro = response.data.data;
            });
            GenericDataService.getData('/retroplanning/' + this.rpId + '/getCampAffectation').then((response) => {
                this.campAffectation = response.data.data;
            });
        }
    },
    methods: {
        getMovementOnItem(item){
            let movement = 0;
            //check if remove
            let elementToRemove = this.newAffectation.find((e) => e.user_source_id == item.user_id && (e.assignment_original == item.assignment_original || e.assignment_original == 'Toutes'));
            if(elementToRemove)
                movement -= elementToRemove.nbToRemove

            //check des ajouts
            this.newAffectation.forEach(affectation => {
                if(affectation.assignment_original == item.assignment_original || item.assignment_original == 'Toutes'){
                    for (const [user_id, nb] of Object.entries(affectation.reaffectation)) {
                       if(item.user_id == user_id)
                            movement += nb
                    }
                    
                }
            });


            return movement
        },
        addNewAffectation(){
            let itemToAdd = {
                user_source_id: this.toReaffect.user_id,
                assignment_original: this.toReaffect.assignment_original,
                nbToRemove : parseInt(this.toReaffect.toReaffect) - this.getNewCount(),
                reaffectation : {}
            }
            this.affectationAvailable.forEach(element => {
                if(element.toAdd && parseInt(element.toAdd) > 0)
                    itemToAdd.reaffectation[element.user_id] = parseInt(element.toAdd)
            });
            //this.newAffectation.push(itemToAdd);
            //envoi de la data
            GenericDataService.postData('/retroplanning/'+this.rpId+'/setNewAffectation', itemToAdd).then((response)=>{
                GenericDataService.getData('/retroplanning/'+this.rpId+'/getCampAffectation').then((response) =>{
                    this.campAffectation = response.data.data
                    this.reaffectDialog = false
                    this.expanded = []
                    this.affectationKey ++
                })
            })
            //this.reaffectDialog = false
        },
        getCountElement(item){

            if(parseInt(item.toAdd) < 0)
                item.toAdd = 0

            if(parseInt(item.toAdd) > parseInt(this.toReaffect.toReaffect))
                item.toAdd = this.toReaffect.toReaffect
            
            item.newTotal = parseInt(item.n) + parseInt(item.toAdd)
            let tempSolde = this.getNewCount()
            if(tempSolde < 0)
                this.cleanAffectations(-tempSolde, item);
            else
                this.newSolde = tempSolde
    
            this.affectationKey ++
        },
        cleanAffectations(nbToRemove, itemToExclude) {
            let affectationToClean = this.affectationAvailable.filter((e)=>e.user_id != itemToExclude.user_id).sort((a, b) => (a.toAdd < b.toAdd) ? 1 : -1)
            for (let index = 0; index < nbToRemove; index++) {
                affectationToClean[0].toAdd -= 1
                affectationToClean[0].newTotal -= 1
                affectationToClean = affectationToClean.sort((a, b) => (a.toAdd < b.toAdd) ? 1 : -1) 
            }
            this.newSolde = this.getNewCount()
        },
        getNewCount(){
            let reaffected = 0;
            this.affectationAvailable.forEach(element => {
                reaffected += parseInt(element.toAdd);
            });

            return parseInt(this.toReaffect.toReaffect) - reaffected
        },
        reaffectFor(item){
            this.toReaffect = item;
            this.newSolde = this.toReaffect.toReaffect;
            this.affectationAvailable = this._.cloneDeep(this.campAffectation.data.filter((e) => e.user_id != item.user_id && e.user != 'Total'));
            this.affectationAvailable.forEach(element => {
                element.toAdd = 0
                element.newTotal = parseInt(element.n) + parseInt(element.toAdd);
                delete element.closed
                delete element.new
                delete element.appealToGenerate
            });
            this.reaffectDialog = true;
        },
        clickColumn(slotData) {
            if(slotData.item.items && slotData.item.items.length > 0){
                const indexRow = slotData.index;
                const indexExpanded = this.expanded.findIndex(i => i === slotData.item);
                if (indexExpanded > -1) {
                    this.expanded.splice(indexExpanded, 1)
                } else {
                    this.expanded = [slotData.item];
                }
            }
        },
        itemRowBackground(item){
            if(item.user_id == 'total')
                return 'lightgrey'
        },
        resetAffectations() {
            GenericDataService.postData('/retroplanning/'+this.rpId+'/regenerateCampCallAffectation').then((response)=>{
                GenericDataService.getData('/retroplanning/'+this.rpId+'/getCampAffectation').then((response) =>{
                    this.campAffectation = response.data.data
                    this.loading = false;
                })
            })
        },
        saveAffectation() {
            this.loading = true;
            GenericDataService.postData('/retroplanning/'+this.rpId+'/saveNewAffectation').then((response)=>{
                this.$emit('closeModal');
                this.loading = false;
            });
        }
    },
}
</script>
<style lang="scss">
    .subDatatable {
        thead{
            height:0px !important;
        }
       
    }
    .newSolde {
        background-color: var(--v-lightgrey-base) !important;
    }
</style>