<template>
    <v-card rounded="lg" class="lightgrey superlead-card">
        <v-toolbar tile dark class="bg-gradient">
            <v-btn depressed color="white" class="square mr-2" text @click.native="cancel(false)">
                <v-icon dark small>$prev</v-icon>
            </v-btn>
            <v-toolbar-title>{{ superlead.id ? ($t('lead_detail') + ' : #S' + superlead.id) : $t('create_a_lead') }}</v-toolbar-title>
            <v-spacer></v-spacer>
                <v-btn depressed outlined color="white" class="mr-3" @click="cancel(false)">
                {{$t('back')}}
            </v-btn>
            <v-btn depressed outlined color="white" :loading="loader" dark @click="saveAndClose" :disabled="btnDisabled || formDisabled">
                {{$t('recordRdvLabel')}}
            </v-btn>
        </v-toolbar>
        
        <v-card-text class="pa-8">
            <v-row>
                <v-col cols="6">
                    <v-alert v-if="reportNumber > 0" class="mb-0" dense border="left" color="info" text>
                        {{ $t("leadReportNumber", {var: reportNumber}) }}
                    </v-alert>
                </v-col>
                <v-col cols="6" align="right">
                    <v-tooltip bottom :disabled="!btnDisabled">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-inline-block">
                                <v-btn v-if="$func.hasRight('exchange/get')" dense :disabled="btnDisabled || formDisabled" @click="launchGetInTouch" :loading="launchGetInTouchLoader"  depressed class="mr-2" color="primary"><v-icon left dark small>$mailbox_flag_up_l</v-icon>{{ $t('getintouch') }}</v-btn>
                                <v-btn dense :disabled="btnDisabled || formDisabled" @click="launchTransfer" :loading="launchTransferLoader"  depressed class="mr-2" color="secondary"><v-icon left dark small>$exchange_l</v-icon>{{ $t('transfer') }}</v-btn>
                                <v-btn dense :disabled="btnDisabled || formDisabled" @click="launchAbandon" :loading="launchAbandonLoader"  depressed class="mr-2" color="error"><v-icon left dark small>$close</v-icon>{{ $t('abandon') }}</v-btn>
                                <v-btn dense :disabled="btnDisabled || formDisabled" @click="launchReport" :loading="launchReportLoader"  depressed class="mr-2" color="warning"><v-icon left dark small>$alarm_clock_l</v-icon>{{ $t('report') }}</v-btn>
                                <v-btn dense v-if="$func.hasRight('lead/qualify')" :disabled="btnDisabled || formDisabled" @click="launchQualif" :loading="launchQualifLoader"  depressed color="success"><v-icon left dark small>$check_l</v-icon>{{ $t('qualif') }}</v-btn>
                            </div>
                        </template>
                        <span>{{ $t('contact_and_rdv_or_demand_are_required') }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="8">
                    <v-card class="" rounded="lg">
                        <v-card-text class="heading pa-8">
                            <div class="header-div">
                                <v-icon medium color="secondary" class="mr-5">$user_d</v-icon>
                                <span>
                                    <span>{{ $t('in_charge_of_the_lead') }}</span>
                                    <div class="label mt-1">{{ superlead.affected_user &&  typeof superlead.affected_user === 'object' ? superlead.affected_user.firstname+' '+superlead.affected_user.name : $t('poollead') }}</div>
                                </span>
                            </div>
                            <div class="header-div">
                                <v-icon medium color="secondary" class="mr-5">$mapmarker_d</v-icon>
                                <span>
                                    <span>{{ $t('attached_to') }}</span>
                                    <div class="label mt-1">{{ superlead.entity_id.label }}</div>
                                </span>
                            </div>
                            <div class="header-div">
                                <v-icon medium color="secondary" class="mr-5">$bullhorn_d</v-icon>
                                <span>
                                    <span>{{ $t('operation') }}</span>
                                    <div class="label mt-1">{{ superlead.operation_id.label }}</div>
                                </span>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <SHistory :superlead="superlead" @changeReportNumber="changeReportNumber" :key="historyKey" />
                </v-col>
            </v-row>
            

            <v-row>
                <v-col cols="8">
                    <SContact :superlead="superlead" :superleadBase="superleadBase" :formDisabled="!(this.$func.hasRight('lead/add') && this.newSuperlead) && formDisabled" ref="sContact" @reloadDemands="demandKey++;" />
                </v-col>

                <v-col cols="4">
                    <SRdv :superlead="superlead" :operationData="operationData" :formDisabled="formDisabled" @rdvUserChange="rdvUserChange" @goToOperationTeam="goToOperationTeam" ref="sRdv" />   
                </v-col>
            </v-row>

            <v-row :key="'demandPart_'+demandKey">
                <v-col cols="4" v-for="(demand, index) in superlead.demands" :key="index" v-if="demand" :style="['rendez-vous', 'passage', 'inscription'].includes(demand.type) ? 'display:none;' : ''">
                    <SDemand :superlead="superlead" :superleadBase="superleadBase" :formDisabled="formDisabled" :demand="demand" :index="index" @removeDemand="removeDemand(index)" :ref="'SDemand_'+index"/>        
                </v-col>
                <v-col cols="4">
                    <SDemandAdd :superlead="superlead" @addDemand="addDemand" :formDisabled="formDisabled" />
                </v-col>
            </v-row>
        </v-card-text>

        <v-dialog v-model="confirmExitDialog" max-width="700">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('checkBeforeExitFormWithoutSave') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed text class="square" @click="confirmExitDialog = false">
                        <v-icon small> $close </v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-actions class="py-6">
                    <v-spacer></v-spacer>
                    <v-btn depressed color="lightgrey" @click="cancel(true)">{{ $t('exitWithoutSave') }}</v-btn>
                    <v-btn depressed color="primary" @click="saveAndClose">{{ $t('saveAndExit') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog class="rounded-lg" persistent width="1200" v-model="transferDialog" scrollable>
            <v-card>
                <v-toolbar tile flat dark  class="bg-gradient" >
                    <div style="display:flex; width:100%; align-items:center; justify-content:space-between;">
                        <div>
                            <v-toolbar-title>{{$t('transferCall')}}</v-toolbar-title>
                        </div>
                        <div>
                            <v-btn outlined dark depressed @click.native="transferDialog = false">{{$t('cancel')}}</v-btn>
                            <v-btn outlined dark depressed @click="transfer" :loading="transferLoader" class="ml-3">{{$t('confirm')}}</v-btn>
                        </div>
                    </div> 
                </v-toolbar>
                <v-card-text class="mt-4">
                    <div v-if="$store.state.auth.user.is_presta">
                        <p style="width:100%;" class="label mb-1"><b>{{ $t('Transfert_choiceTitle') }}<span class="error--text"> *</span></b></p>
                        <v-btn-toggle dense group borderless :mandatory="true" v-model="transfert_choice" active-class="btnActive" class="mb-4">
                            <v-btn dense dark depressed class="white--text btnSpec" :style="transfert_choice == property.value ? 'background-color:var(--v-primary-base) !important;' : 'background-color:var(--v-darkgrey-base) !important;'" v-show="!property.hide" v-for="property in transfert_choices" :key="property.value" :value="property.value"> 
                                {{$t(property.label)}}
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                    <v-simple-table v-if="team.length > 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left"></th>
                                    <th class="text-left">{{$t('interlocutors')}}</th>
                                    <th class="text-left"></th>
                                    <th class="text-left">{{$t('brands')}}</th>
                                    <th class="text-left">{{$t('phones')}}</th>
                                    <th class="text-left">{{$t('leadReceiveToday')}}</th>
                                    <th class="text-left">{{$t('leadReceiveLastWeek')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in team" :key="user.id" @click="selectUser(user.id)">
                                    <td>
                                        <div style="display:flex; align-items:center;">
                                            <input type="radio" class="radioButton" :id="'radio_'+user.id" :value="user.id" v-model="userRadio" :name="'userRadio'"/>
                                        </div>
                                    </td>
                                    <td>
                                        <b>{{ user.firstname }} {{ user.name }}</b>
                                        <div v-if="user.poste && user.poste.length > 0">{{user.poste.join(', ')}}</div>
                                    </td>
                                    <td>
                                        <div v-for="(univ, index) in user.univers" class="my-1" :key="'univers_'+index">
                                            <v-chip small color="primary" outlined>{{univ}}</v-chip>
                                        </div>
                                    </td>
                                    <td>{{ user.brands.join(', ') }}</td>
                                    <td>
                                        <div v-for="(phone, index) in user.phones" class="my-1" :key="'phone_'+index">
                                            <v-chip small color="primary" outlined>{{phone.type}}</v-chip>
                                            {{phone.number}}
                                        </div>
                                    </td>
                                    <td class="text-center">
                                       <b>{{user.leadReceiveToday}}</b>
                                    </td>
                                    <td class="text-center">
                                        <b>{{user.leadReceiveWeek}}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-dialog>

        <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'"/>
    </v-card>
</template>

<script>
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import GenericDataService from '@/services/GenericDataService'
import SContact from "@/components/superleadComponent/cards/s-contact";
import SRdv from "@/components/superleadComponent/cards/s-rdv";
import SDemand from "@/components/superleadComponent/cards/s-demand";
import SDemandAdd from "@/components/superleadComponent/cards/s-demand-add";
import SHistory from "@/components/superleadComponent/cards/s-history";
import getForm from "@/mixins/mixins";

export default {
    name: "superleadComponent",
    props: [],
    components: { FormDialogComponent, SContact, SRdv, SDemand, SDemandAdd, SHistory },
    mixins: [getForm],
    data() {
        return {
            superlead: {},
            resolve: null,
            btnDisabled: true,
            formDisabled: true,
            demandKey:0,
            confirmExitDialog: false,
            transferDialog: false,
            transfert_choices: [
                { "value": "CONCESS", "label": "transfert_choice_CONCESS_label" },
                { "value": "TEAM", "label": "transfert_choice_TEAM_label" }
            ],
            transfert_choice: "CONCESS",
            team: [],
            userRadio: null,
            loader: false,
            transferLoader: false,
            launchTransferLoader: false,
            launchGetInTouchLoader: false,
            launchReportLoader: false,
            launchAbandonLoader: false,
            launchQualifLoader: false,
            form: null,
            operationData: false,
            newSuperlead: false,
            reportNumber:0,
            mainTypeSetted: false,
            socketServer: null,
            historyKey: 0
        }
    },
    created() {
        this.superlead =  this._.cloneDeep(this.superleadBase);
        this.$watch('superlead.rdv', () => {
            if(this.socketServer) {
                this.socketServer.send(JSON.stringify({
                    type: 'rdv',
                    account: this.$store.state.auth.currentAccountId,
                    operation: (this.superlead.operation_id.id ? this.superlead.operation_id.id : this.superlead.operation_id),
                    rdv: this.superlead.rdv
                }));
            }
        }, { deep: true });
        
        if (!!!this.superleadBase.id) {
            this.newSuperlead = true;
        }
        GenericDataService.getData('/operation/' + (this.superlead.operation_id.id ? this.superlead.operation_id.id : this.superlead.operation_id) + '/checkOperationData').then((response) => {
            this.operationData = response.data.data
        });
        if(this.superlead.script) {
            this.$store.dispatch("auth/SET_OPEN_CALL_SCRIPT_DRAWER_WITHOUT_ID", true);
            this.$store.dispatch("auth/SET_TEMPLATE_FOR_CALL_SCRIPT_DRAWER", this.superlead.script);
        }
        
        const socketServer = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL);
        this.socketServer = socketServer;
        
        socketServer.onopen = (e) => {
            if (this.newSuperlead && this.superlead.rdv && this.superlead.rdv.operation_id) {
                if(this.socketServer) {
                    this.socketServer.send(JSON.stringify({
                        type: 'rdv',
                        account: this.$store.state.auth.currentAccountId,
                        operation: (this.superlead.operation_id.id ? this.superlead.operation_id.id : this.superlead.operation_id),
                        rdv: this.superlead.rdv
                    }));
                }
            }
        }
    },
    watch: {
        superlead: {
            handler(val, old){
                //watcher pour savoir si on active les boutons d'actions : 
                // règle : si on a au moins une demande ou un rdv de défini et un contact alors on active. au clic sur les boutons d'actions, si non enregistré, on enregistrera le superlead avant de faire l'action cliquée
                this.mainTypeSetted = false;
                
                if ((this.$func.hasRight('lead/add') && this.newSuperlead) || (this.$func.hasRight('lead/update') && !this.newSuperlead)) {
                    this.formDisabled = false;
                } else {
                    this.formDisabled = true;
                }

                if ((val.hasOwnProperty('contact_id') && ((val.contact_id.hasOwnProperty('firstname') && val.contact_id.firstname != '') || val.contact_id.hasOwnProperty('name') && val.contact_id.name != ''))
                    && ((val.hasOwnProperty('demands') && val.demands.length > 0) || (val.hasOwnProperty('rdv') && val.rdv && val.rdv.hasOwnProperty('status')))) {
                    this.btnDisabled = false;
                } else {
                    this.btnDisabled = true;
                }
                if(val.hasOwnProperty('demands') && val.demands.length > 0){
                    val.demands.forEach(element => {
                        if(element.type == this.operationData.mainType && !this.mainTypeSetted){
                            element['details.mainDemand'] = true;
                            this.mainTypeSetted = true;
                        }
                    });
                }
                
            },
            deep:true,
            immediate:true
        },
        transfert_choice (transfert_choice) {
            this.getUsersForTransfert();
        },
        openExchangeDrawer(val) {
            if(!val)
                this.historyKey++;
        }
    },
    computed: {
        superleadBase: function(){
            return this.$store.state.lead.superleadBase;
        },
        openExchangeDrawer: function(){
            return this.$store.state.exchange.openExchangeDrawer;
        },
    },
    methods: {
        changeReportNumber(val){
            this.reportNumber = val
        },
        rdvUserChange(val){
            this.$set(this.superlead, 'affected_user', val)
            this.$set(this.superleadBase, 'affected_user', val)
        },
        cancel(forceExit = false) {
            // Console.log à laisser pour le debug
            console.log("SuperleadBase : ", this.superleadBase);
            console.log("Superlead : ", this.superlead);

            if(!this._.isEqual(this.superleadBase, this.superlead) && !forceExit) {
                this.confirmExitDialog = true;
            } else{
                if (this.socketServer) {
                    this.socketServer.send(JSON.stringify({
                        type: 'close',
                        account: this.$store.state.auth.currentAccountId,
                        operation: (this.superlead.operation_id.id ? this.superlead.operation_id.id : this.superlead.operation_id)
                    }));
                }
                this.$store.dispatch("lead/CLOSE_SUPERLEAD_DIALOG", false);
                this.$emit("reloadRouter");
            }
                
        },
        goToOperationTeam(){
            this.$router.push({name:'OperationsDetailTeam', params: {operation_id:(this.superlead.operation_id.id ? this.superlead.operation_id.id : this.superlead.operation_id)}});
            this.$store.dispatch("lead/CLOSE_SUPERLEAD_DIALOG", false);
        },
        saveAndClose(){
            this.confirmExitDialog = false;
            this.loader = true;
            this.saveSuperlead().then((response) => {
                this.loader = false;
                this.cancel(true);
                this.$emit("reloadRouter");
            }).catch(e => {
                this.loader = false;
            })
        },
        async saveSuperlead() {
            if(this.$refs.sRdv.validateSRdv() || this.validateAllDemand()){
                await GenericDataService.postData('superlead/saveSuperlead', this.superlead).then((response) => {
                    this.superlead = response.data.data
                    this.$store.dispatch('lead/SET_SUPERLEAD_BASE', response.data.data)
                    this.demandKey++;
                    this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                })
            } else {
                throw new Error('data missing');
            }
        },
        validateAllDemand() {
            if(this.superlead.hasOwnProperty('demands') && this.superlead.demands.length > 0){
                let valid = true
                this.superlead.demands.forEach((element, index) => {
                    if(!this.$refs['SDemand_'+index][0].validateSDemand()){
                        valid = false
                    }                    
                });
                return valid
            }
        },
        addDemand(type) {
            this.superlead.demands.push({ "type": type });
        },
        removeDemand(index) {
            this.superlead.demands.splice(index, 1);
            this.demandKey++;
        },
        launchTransfer(){
            this.launchTransferLoader = true;
            this.saveSuperlead().then((response) => {
                this.launchTransferLoader = false;
                this.getUsersForTransfert();
                this.transferDialog = true;
            }).catch(e => {
                this.launchTransferLoader = false;
            })
        },
        transfer() {
            this.transferLoader = true;
            GenericDataService.getData('/superlead/transferSuperlead?id=' + this.superlead.id + '&user_id=' + this.userRadio).then((response) => {
                this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                this.transferLoader = false;
                this.cancel(true);
                this.$emit("reloadRouter");
            }).catch(e => {
                this.transferLoader = false;
            })
        },
        getUsersForTransfert() {
            if(this.transfert_choice === "TEAM") {
                GenericDataService.getData('user/selectListForTransfertTable').then((response) => {
                    this.team = response.data.data
                });
            } else {
                GenericDataService.getData('/operation/' + (this.superlead.operation_id.id ? this.superlead.operation_id.id : this.superlead.operation_id) + '/getTeamActive').then((response) => {
                    this.team = response.data.data
                    if(this.superlead.rdv)
                        this.team = this.team.filter((user) => user.confPlanning);
                });
            }
        },
        selectUser(id) {
           this.userRadio = id 
        },
        launchAbandon() {
            this.launchAbandonLoader = true;
            this.saveSuperlead().then((response) => {
                this.launchAbandonLoader = false;
                this.getForm('/superlead/getForm?formName=abandon&id=' + this.superlead.id);
            }).catch(e => {
                this.launchAbandonLoader = false;
            })
        },
        launchReport() {
            this.launchReportLoader = true;
            this.saveSuperlead().then((response) => {
                this.launchReportLoader = false;
                this.getForm('/superlead/getForm?formName=report&id=' + this.superlead.id);
            }).catch(e => {
                this.launchReportLoader = false;
            })
        },
        launchQualif() {
            this.launchQualifLoader = true;
            this.saveSuperlead().then((response) => {
                this.launchQualifLoader = false;
                this.getForm('/superlead/getQualifForm&id=' + this.superlead.id);
            }).catch(e => {
                this.launchQualifLoader = false;
            })
        },
        launchGetInTouch() {
            this.launchGetInTouchLoader = true;
            GenericDataService.getData('/contact/' + this.superlead.contact_id.id + '/get').then((response) => {
                this.$store.dispatch("contact/SET_CONTACT_DATA", response.data.data);
                this.$store.dispatch("exchange/SET_OPEN_EXCHANGE_DRAWER", { value: true, superlead: this.superlead });
                this.launchGetInTouchLoader = false;
            }).catch(e => {
                this.launchGetInTouchLoader = false;
            })
        },
        customRefresh() {
            this.cancel(true);
            this.$emit("reloadRouter");
        }
    }
}
</script>

<style lang="scss">
    .superlead-card {
        .heading{
            display:flex;
            justify-content: space-between;
            align-items:center;
            .header-div {
                display: flex;
                align-items: center;
                .label {
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }
</style>